import i18n from "shared/runners/i18n"
import DesignationsInput from "church_center/components/donation_form/designations_input"
import ErrorMessage from "church_center/components/shared/error_message"
import FindPaymentMethod from "church_center/components/find_payment_method"
import FindPerson from "church_center/components/find_person"
import PersonBadge from "church_center/components/shared/person_badge"
import LoginButton from "church_center/components/find_payment_method/login_button"
import LogoutButton from "church_center/components/donation_form/logout_button"
import RecurringFrequencyInput from "church_center/components/donation_form/recurring_frequency_input"
import SubmitDonationForm from "church_center/components/donation_form/submit_donation_form"
import SubmitRecurringDonationForm from "church_center/components/donation_form/submit_recurring_donation_form"
import SubmitUnverifiedPaymentMethodForm from "church_center/components/donation_form/submit_unverified_payment_method_form"
import BackgroundDonationStatus from "church_center/components/background_donation_status"
import MoneyInput from "shared/components/money_input"
import { ccoPerson, fund as fundProp, inProgressDonationFormState } from "shared/utils/prop_types"
import { designationsAmountToNumber, scheduleFrequency } from "church_center/utils/designations"
import { featureEnabled } from "shared/runners/flipper"
import React, { Fragment } from "react"
import { shape, string, bool, arrayOf, object, number } from "prop-types"
import colors from "church_center/utils/colors"
import { css } from "glamor"
import classNames from "classnames"

export default class DonationForm extends React.Component {
  static propTypes = {
    card_giving_level: string.isRequired,
    direct_link_fund_slug: string,
    default_amount: string,
    accept_apple_pay: bool.isRequired,
    ach_allowed: bool.isRequired,
    fee_coverage_allowed: bool.isRequired,
    five_dollar_minimum_donation_amount: bool.isRequired,
    error: string,
    funds: arrayOf(fundProp),
    is_recurring: bool,
    logged_in_person: ccoPerson,
    organization: shape({
      account_center_id: number.isRequired,
      country: string.isRequired,
      currency: string.isRequired,
      name: string.isRequired,
    }).isRequired,
    routes: object.isRequired,
    browser_receipt_id: string,
    donation_person_is_mailable: bool,
    __html: string,
    loginRedirectUrl: string,
    in_progress_donation: inProgressDonationFormState,
    selected_payment_method_id: number,
  }

  static defaultProps = {
    error: null,
    funds: [],
    is_recurring: false,
    logged_in_person: null,
  }

  constructor(props) {
    super(props)
    const fund =
      (props.direct_link_fund_slug &&
        props.funds.find(
          (f) =>
            f.id.toString() === props.direct_link_fund_slug ||
            f.slug === props.direct_link_fund_slug
        )) ||
      props.funds[0]

    const defaultState = {
      funds: props.funds,
      designations: [
        {
          amount: MoneyInput.minimalTextValue(props.default_amount),
          fund_id: fund.id,
          name: fund.name,
          description: fund.description,
        },
      ],
      isRecurring: props.is_recurring,
      loading: false,
      person: props.logged_in_person,
      schedule: { weekday: { weekday: 0 } },
      isCoveringFee: false,
      browserReceiptId: props.browser_receipt_id,
      error: props.error,
    }

    this.state = this.props.in_progress_donation
      ? {
          ...defaultState,
          isRecurring: this.props.in_progress_donation.isRecurring,
          isCoveringFee: this.props.in_progress_donation.isCoveringFee,
          designations: this.props.in_progress_donation.designations,
          schedule: this.props.in_progress_donation.schedule,
          next_occurrence: this.props.in_progress_donation.next_occurrence,
        }
      : defaultState
  }

  componentWillReceiveProps(nextProps) {
    if (nextProps.browser_receipt_id !== this.props.browser_receipt_id) {
      this.setState({ browserReceiptId: nextProps.browser_receipt_id })
    }

    if (
      nextProps.logged_in_person &&
      !_.isEqual(nextProps.logged_in_person.payment_methods, this.state.person.payment_methods)
    ) {
      this.handleChangePerson(nextProps.logged_in_person)
    }

    this.setState({ error: nextProps.error })
  }

  handleChangeDesignations = (designations) => {
    this.setState({ designations })
  }

  handleChangePerson = (person) => {
    this.setState({ person })
  }

  handleChangeRecurrence = ({ isRecurring, schedule, next_occurrence }) => {
    this.setState({ isRecurring, schedule, next_occurrence })
  }

  handleChangeCoveringFee = (isCoveringFee) => {
    this.setState({ isCoveringFee })
  }

  handleTryAgain = () => {
    this.setState({ browserReceiptId: null })
  }

  handleReset = () => {
    this.setState({ error: null })
  }

  validateDesignationsForApplePay = () => {
    const error = this.getDesignationsError()
    return { error }
  }

  captureInProgressState = () => {
    return {
      isRecurring: this.state.isRecurring,
      isCoveringFee: this.state.isCoveringFee,
      person: this.state.person,
      designations: this.state.designations,
      schedule: this.state.schedule,
      next_occurrence: this.state.next_occurrence,
    }
  }

  getDesignationsError = () => {
    const fiveDollarMinimum = this.props.five_dollar_minimum_donation_amount
    const minimumTotalSatisfied =
      designationsAmountToNumber(this.state.designations) >= (fiveDollarMinimum ? 5 : 0.5)

    if (!minimumTotalSatisfied)
      return `The minimum donation is $${fiveDollarMinimum ? "5.00" : "0.50"}`

    const everyDesignationHasAnAmount = this.state.designations.every(
      ({ amount }) => (parseFloat(amount) || 0) !== 0
    )

    if (!everyDesignationHasAnAmount) return "Every donation needs an amount of at least $0.01"

    return null
  }
  render() {
    const isLoggedIn = !!this.props.logged_in_person
    const {
      isRecurring,
      schedule,
      next_occurrence,
      person,
      browserReceiptId,
      account_center_id,
      addingUnverifiedPaymentMethod,
    } = this.state
    const designationsError = this.getDesignationsError()
    const { loginRedirectUrl } = this.props

    const applePay = {
      designations: this.state.designations,
      description: isRecurring ? `Charged ${scheduleFrequency(schedule)}` : null,
      organizationName: this.props.organization.name,
      validate: this.validateDesignationsForApplePay,
    }

    return (
      <div>
        {browserReceiptId && (
          <BackgroundDonationStatus
            __html={this.props.__html}
            sgid={browserReceiptId}
            person_is_mailable={this.props.donation_person_is_mailable}
            onTryAgain={this.handleTryAgain}
          />
        )}
        <div className={browserReceiptId ? "d-n" : ""}>
          {!addingUnverifiedPaymentMethod && (
            <Fragment>
              <div className="d-f ai-c jc-sb mb-1">
                <h1 className="h3">Give</h1>
                <div
                  aria-label="Currency identifier"
                  className="d-f ai-c fs-5 lh-1.25"
                  {...css({
                    backgroundColor: colors.tint7,
                    padding: "4px 8px",
                    borderRadius: 3,
                  })}
                >
                  <span className="d-ib mr-2p">$</span>
                  <span className="d-ib tt-u">{i18n.currency_code}</span>
                </div>
              </div>

              <DesignationsInput
                designations={this.state.designations}
                funds={this.state.funds}
                onChange={this.handleChangeDesignations}
              />

              <RecurringFrequencyInput
                isRecurring={isRecurring}
                onChange={this.handleChangeRecurrence}
                schedule={schedule}
                next_occurrence={next_occurrence}
              />
            </Fragment>
          )}

          {isLoggedIn && (
            <div className="my-4">
              <div className="d-f jc-sb ai-c mb-1">
                <h2 className="h4">My information</h2>
                <a
                  href={this.props.routes.profile_donations_url}
                  className="btn minor-btn secondary-btn"
                >
                  My Giving
                </a>
              </div>
              <PersonBadge person={this.state.person}>
                <LogoutButton className="d-n d-b@iframe fs-4" />
              </PersonBadge>
            </div>
          )}
          {!isLoggedIn && !this.state.person && (
            <div className="d-n d-b@iframe action-drawer fs-4 mt-4 mb-1">
              <LoginButton
                accountCenterId={account_center_id}
                emailAddress=""
                redirectUrl={loginRedirectUrl}
                submitText="Log in"
                className="fw-600 link-btn"
              />{" "}
              to access saved payment methods.
            </div>
          )}

          {!isLoggedIn && <FindPerson autoSelect onChange={this.handleChangePerson} />}

          {person && (
            <FindPaymentMethod
              acceptApplePay={this.props.accept_apple_pay}
              applePay={applePay}
              cardGivingLevel={this.props.card_giving_level}
              country={this.props.organization.country}
              currency={this.props.organization.currency}
              achAllowed={this.props.ach_allowed}
              loading={this.state.loading}
              paymentMethods={person.payment_methods}
              person={this.state.person}
              isRecurring={isRecurring}
              isCoveringFee={this.state.isCoveringFee}
              feeCoverageAllowed={this.props.fee_coverage_allowed}
              onChangeCoveringFee={this.handleChangeCoveringFee}
              key={browserReceiptId || "fpm"}
              onReset={this.handleReset}
              organizationAccountCenterId={this.props.organization.account_center_id}
              inProgressDonationFormState={this.captureInProgressState()}
              newInProgressDonationUrl={this.props.routes.new_in_progress_donation_url}
              newPaymentMethodUrl={this.props.routes.new_payment_method_url}
              profilePaymentMethodsUrl={this.props.routes.profile_payment_methods_url}
              selectedPaymentMethodId={this.props.selected_payment_method_id}
            >
              {({
                paymentMethod,
                loading,
                requestToken,
                submitUnverifiedPaymentMethod,
                verificationMethod,
                autoSubmit,
              }) => {
                return (
                  <>
                    {!this.state.loading && this.state.error && (
                      <div className="action-drawer pt-4p pb-1">
                        <ErrorMessage
                          className={classNames({
                            "ml-6@sm": !featureEnabled("stripe_payment_element"),
                          })}
                          style={{ marginTop: 0, marginBottom: 0 }}
                        >
                          {this.state.error}
                        </ErrorMessage>
                      </div>
                    )}
                    {submitUnverifiedPaymentMethod ? (
                      <SubmitUnverifiedPaymentMethodForm
                        loading={loading}
                        onRequestToken={requestToken}
                        paymentMethod={paymentMethod}
                        person={this.state.person}
                        url={this.props.routes.unverified_payment_methods_url}
                        verificationMethod={verificationMethod}
                        autoSubmit={autoSubmit}
                      />
                    ) : designationsError ? (
                      <ErrorMessage>{designationsError}</ErrorMessage>
                    ) : isRecurring ? (
                      <SubmitRecurringDonationForm
                        designations={this.state.designations}
                        loading={loading}
                        feeCoverageAllowed={this.props.fee_coverage_allowed}
                        isCoveringFee={this.state.isCoveringFee}
                        onChangeCoveringFee={this.handleChangeCoveringFee}
                        onRequestToken={requestToken}
                        paymentMethod={paymentMethod}
                        person={this.state.person}
                        schedule={this.state.schedule}
                        next_occurrence={this.state.next_occurrence}
                        url={this.props.routes.recurring_donations_url}
                        redirectOnNondeterministicAjaxError
                      />
                    ) : (
                      <SubmitDonationForm
                        designations={this.state.designations}
                        loading={loading}
                        feeCoverageAllowed={this.props.fee_coverage_allowed}
                        isCoveringFee={this.state.isCoveringFee}
                        onRequestToken={requestToken}
                        onChangeCoveringFee={this.handleChangeCoveringFee}
                        paymentMethod={paymentMethod}
                        person={this.state.person}
                        url={this.props.routes.donations_url}
                        redirectOnNondeterministicAjaxError
                      />
                    )}
                  </>
                )
              }}
            </FindPaymentMethod>
          )}
        </div>
      </div>
    )
  }
}
