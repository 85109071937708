import StripeWithGuard from "shared/runners/stripe_with_guard"

let stripe
const config = {}

export function run({
  create_setup_intent_url,
  setup_intent_confirmed_url,
  planning_center_source_app_attribute,
}) {
  config.createSetupIntentUrl = create_setup_intent_url
  config.setupIntentConfirmedUrl = setup_intent_confirmed_url
  config.planningCenterSourceAppAttribute = planning_center_source_app_attribute

  StripeWithGuard.promise().then(() => {
    stripe = StripeWithGuard.instance()
  })
}

export function fetchClientSecret({ personAccountCenterId, personEmailAddress }) {
  const url = config.createSetupIntentUrl
  const data =
    personAccountCenterId && personEmailAddress
      ? {
          person: {
            account_center_id: personAccountCenterId,
            email_address: personEmailAddress,
          },
        }
      : {}
  return new Promise((resolve, reject) => {
    jQuery.ajax({
      url,
      type: "POST",
      headers: { "X-CSRF-Token": $.rails.csrfToken() },
      data,
      success: (response) => {
        resolve(response.client_secret)
      },
      error: (_xhr, _status, error) => {
        reject(error)
      },
    })
  })
}

export function confirmSetupIntent({
  elements,
  personName,
  personEmailAddress,
  organizationAccountCenterId,
}) {
  const returnUrl = config.setupIntentConfirmedUrl
  return stripe.confirmSetup({
    elements,
    redirect: "if_required",
    confirmParams: {
      payment_method_data: {
        billing_details: {
          name: personName,
          email: personEmailAddress,
        },
        metadata: {
          [config.planningCenterSourceAppAttribute]: "Giving",
          planning_center_organization_id: organizationAccountCenterId,
        },
      },
      return_url: returnUrl,
    },
  })
}

export default exports
